<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2 class="purple bold">Editar usuário</h2>
      </b-col>
    </b-row>

    <b-row class="teacher-profile mt-50">
      <b-col md="3" v-if="User.role_id != 10">
        <ProfilePhoto :photo="photo ? photo : null" :callback="setNewPhoto" />
      </b-col>

      <b-col>
        <b-row>
          <b-col>
            <b-form-group class="required" description>
              <label for="label">Nome</label>
              <b-form-input
                type="text"
                placeholder
                v-model="User.name"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group class="required" description>
              <label for="label">E-mail</label>
              <b-form-input
                type="text"
                placeholder
                v-model="User.email"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col v-if="User.role_id != 10">
            <b-form-group description>
              <label for="label">Telefone</label>
              <b-form-input
                v-for="item in User.Phones"
                :key="item.id"
                :value="item.number"
                v-model="item.number"
                type="text"
                v-mask="['(##) #####-####']"
                placeholder="(__) _____-____"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col v-if="User.role_id != 10">
            <b-form-group class="required" description>
              <label for="label">CPF</label>
              <b-form-input
                type="text"
                v-model="User.cpf"
                v-mask="['###.###.###-##']"
                placeholder="___.___.___-__"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col v-if="User.role_id != 10">
            <b-form-group description>
              <label for="label">RG</label>
              <b-form-input
                v-model="User.rg"
                type="text"
                v-mask="['#.###.###']"
                placeholder="Somente números"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col v-if="User.role_id != 10">
            <b-form-group class="required" description>
              <label for="label">Data de nascimento</label>
              <b-form-input
                v-model="User.birth_date"
                type="date"
                placeholder
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group class="required" description>
              <label for="label">Função</label>
              <b-form-select
                v-model="User.role_id"
                :options="roles"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row align-v="center" class="mt-5">
      <b-col></b-col>

      <b-col class="text-right">
        <b-button class="large blue outline" :to="{ name: 'usuarios' }"
          >Voltar</b-button
        >
      </b-col>

      <b-col></b-col>

      <b-col class="text-right">
        <b-button @click="submit" class="large blue">Salvar</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ProfilePhoto from "@/components/ProfilePhoto";
import { mapGetters } from "vuex";
import HttpRda from "../../../http-rda.js";
import moment from "moment-timezone";
export default {
  name: "perfil-usuario",
  components: {
    ProfilePhoto,
  },
  data() {
    return {
      id: null,
      User: {
        name: "",
        email: "",
        password: "",
        birth_date: null,
        cpf: "",
        rg: "",
        Phones: [
          {
            number: "",
            is_mobile: false,
          },
        ],
        gender: "",
        role_id: 0,
      },
      photo: null,
      selected: null,
      selectedGender: 0,
      state: "PE",
      country: null,
      breadcrumb: [
        {
          text: "Secretaria",
          href: "/secretaria",
        },
        {
          text: "Usuários",
          href: "/secretaria/usuarios",
        },
        {
          text: "Editar",
        },
      ],
      role_name: null,
      roles_id: null,
      roles: [
        {
          value: 7,
          text: "Coordenação",
        },
        {
          value: 8,
          text: "Secretaria",
        },
        {
          value: 9,
          text: "Portaria",
        },
        {
          value: 10,
          text: "QrCode",
        },
        {
          value: 13,
          text: "Gestor de Faltas e Presenças",
        },
      ],
      gender: [
        {
          value: 0,
          text: "Selecione",
        },
        {
          value: 1,
          text: "Masculino",
        },
        {
          value: 2,
          text: "Feminino",
        },
        {
          value: 3,
          text: "Outro",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      countriesList: "shared/countries",
      statesList: "shared/states",
    }),
  },

  methods: {
    GetUser(id) {
      this.id = id;

      HttpRda.get(`/collaborator/${id}`).then((response) => {
        let data = response.data.user;

        data.rg = data.rg.replace(/^(\d{1})(\d{3})(\d{3})/, "$1.$2.$3");
        data.cpf = data.cpf.replace(
          /^(\d{3})(\d{3})(\d{3})(\d{2})$/g,
          "$1.$2.$3-$4"
        );

        if (!data.Phones || !data.Phones.length) {
          data.Phones = [
            {
              number: "",
              is_mobile: false,
            },
          ];
        }

        if (data.photo) {
          this.photo = data.photo;
        }

        this.User = data;
      });
    },

    setNewPhoto(photo) {
      this.photo = photo;
    },

    submit() {
      if (this.User.role_id == 10) {
        this.User.cpf = "00000000000";
        this.User.rg = "0000000";
        this.User.birth_date = moment().format("YYYY-MM-DD");
      }

      if (
        this.User.name == "" ||
        this.User.email == "" ||
        this.User.cpf == "" ||
        (this.User.birth_date === null && this.User.role_id != 10) ||
        this.User.role_id == 0
      ) {
        this.$swal.fire({
          title: "Alerta",
          text: "Preencha todos os campos!",
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        this.update();
      }
    },

    update() {
      try {
        const data = new FormData();

        if (this.photo !== "" || this.photo !== null) {
          data.append("file", this.photo);
        }

        data.append("name", this.User.name);
        data.append("email", this.User.email);
        data.append("cpf", this.User.cpf);
        data.append("rg", this.User.rg);
        data.append("gender", this.User.gender);
        data.append("birth_date", this.User.birth_date);
        data.append("role_id", this.User.role_id);

        if (this.User.Phones[0].number !== "") {
          data.append("Phones[0][number]", this.User.Phones[0].number);
          data.append("Phones[0][is_mobile]", this.User.Phones[0].is_mobile);
        }

        HttpRda.patch(`/collaborator/${this.id}`, data)
          .then(() => {
            this.$swal.fire({
              title: "Sucesso",
              text: "Usuário atualizado com sucesso",
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
            });

            setTimeout(() => {
              this.$router.push("/administracao/usuarios");
            }, 1000);
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.details &&
              error.response.data.details.length > 0
            ) {
              let errorHtml = "";
              error.response.data.details.forEach((err) => {
                errorHtml += `${err}<br>`;
              });

              this.$swal.fire({
                title: "Verifique os erros abaixo!",
                html: errorHtml,
                icon: "error",
                showConfirmButton: true,
                confirmButtonText: "Ok",
              });
            } else {
              this.$swal.fire({
                title: "Algo deu errado!",
                text: "Tente novamente mais tarde!",
                icon: "error",
                showConfirmButton: false,
                timer: 1500,
              });
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
  },

  async created() {
    await this.GetUser(this.$route.params.id);
  },
};
</script>

<style lang="scss">
.teacher-profile {
  .form-group {
    &.required {
      &:after {
        right: 0;
      }
    }
  }
}

.container-rda .multiselect__tag-icon:after {
  color: white;
}

input.isInvalid {
  border-color: red;
}
</style>
